.events {
  --gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: var(--gap);

  & .event-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--padding-small);
    flex-basis: calc((var(--body-width) - (var(--padding-medium) * 2) - var(--gap)) / 2);
    flex-grow: 1;
    flex-shrink: 1;
    background-color: var(--body-background);
    box-shadow: 0px 3px 5px 0 #00000040;

    & h2 {
      margin-top: 0;
      text-align: center;
      flex-grow: 0;

      & span {
        display: block;
        margin-top: 20px;
      }
    }

    & .event-description {
      flex-grow: 1;
    }
  }

  & .event-logo {
    display: block;
    max-width: 100%;
    max-height: 100px;
    width: 100%;
    height: 100px;
    object-fit: contain;
    object-position: center;
  }

  & .event-link {
    margin-top: 5px;
    flex-grow: 0;
    text-align: right;
    font-weight: bold;
    align-self: flex-end;
  }
}
